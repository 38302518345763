import { classNames } from '@/utils';

interface WrapperProps {
  children: JSX.Element[] | JSX.Element;
  noPadding?: boolean;
  className?: string;
}

export default function WrapperContent({ children, noPadding = false, className = '' }: WrapperProps) {
  return (
    <section className={classNames('flex relative flex-col justify-center content-center flex-wrap w-full', noPadding ? '' : 'py-8 md:py-16', className)}>
      <div className="w-full max-w-full box-border">{children}</div>
    </section>
  );
}
