'use client';

import Image from 'next/image';
import errorImg from '_/images/404.svg';
import { useEffect } from 'react';
import { SendEventGTMWithSingleString } from '@/lib/gtm';
import WrapperContent from '@/components/Layout/WrapperContent';

export default function NotFound() {
  useEffect(() => {
    SendEventGTMWithSingleString('page not found');
  }, []);

  return (
    <WrapperContent>
      <div className="fixed w-screen h-screen top-0 left-0 bg-gray-100 z-50 flex justify-center items-center flex-col">
        <title>404 - Ops! Página não encontrada!</title>
        <div className="relative flex flex-col justify-center items-center">
          <div className="w-2/3 md:w-1/2">
            <Image priority height={330} width={0} src={errorImg} alt="Canaltech Ofertas 404" className="w-full" unoptimized />
          </div>

          <div className="relative p-10 flex justify-center items-center text-center flex-col mt-6">
            <h2 className="text-3xl font-bold">Ops! Página não encontrada!</h2>
            <p
              className="mt-2
          ">
              Desculpe, não foi possível carregar a página solicitada!
            </p>
            <a className="rounded-full p-1 px-6 bg-primary text-gray-50 mt-6" href="/">
              Voltar ao Início
            </a>
          </div>
        </div>
      </div>
    </WrapperContent>
  );
}
